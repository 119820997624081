import { defineStore } from 'pinia';
import type { Scope, TenantResponse, Privilege, UserResponse } from '@/types';
import axios from 'axios';

type GeneralStoreType = {
  loader: boolean;
  scopes: Scope[];
  clients: TenantResponse[];
  users: UserResponse[];
  privileges: Privilege[];
};

let scopeRequest: Promise<Scope> | null = null;
let clientRequest: Promise<TenantResponse> | null = null;
let privilegeRequest: Promise<Privilege> | null = null;
let userRequest: Promise<UserResponse> | null = null;

export const generalStore = defineStore('generalStore', {
  state: (): GeneralStoreType => {
    return {
      loader: false,
      scopes: [],
      clients: [],
      users: [],
      privileges: [],
    };
  },
  getters: {},
  actions: {
    setLoader(value: boolean) {
      this.loader = value;
    },
    fetchScopes() {
      if (!scopeRequest) {
        scopeRequest = axios
          .get('/api/scopes')
          .then((response) => {
            this.scopes = response.data;
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            return [];
          });
      }
      return scopeRequest;
    },
    fetchClients() {
      if (!clientRequest) {
        clientRequest = axios
          .get('/api/tenants')
          .then((response) => {
            this.clients = response.data;
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            return [];
          });
      }
      return clientRequest;
    },
    fetchUsers() {
      if (!userRequest) {
        userRequest = axios
          .get('/api/users')
          .then((response) => {
            this.users = response.data;
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            return [];
          });
      }
      return userRequest;
    },
    fetchPrivileges() {
      if (window.userData && window.userData.role === 'system-administrator') {
        if (!privilegeRequest) {
          privilegeRequest = axios
            .get('/api/privileges')
            .then((response) => {
              this.privileges = response.data;
              return response.data;
            })
            .catch((error) => {
              console.error(error);
              return [];
            });
        }

        return privilegeRequest;
      }
      return Promise.resolve();
    },

    calculateLastActiveTime(lastActive: string | null | undefined) {
      if (!lastActive) {
        return 'Never';
      }

      const now = Date.now();
      const lastActiveTime = new Date(lastActive).getTime();
      const diffMs = Math.abs(now - lastActiveTime);
      const diffMinutes = Math.floor(diffMs / (1000 * 60));

      if (diffMinutes < 60) {
        return `${diffMinutes} minutes ago`;
      }

      const diffHours = Math.floor(diffMinutes / 60);
      if (diffHours < 24) {
        return `${diffHours} hours ago`;
      }

      const diffDays = Math.floor(diffHours / 24);
      if (diffDays < 10) {
        return `${diffDays} days ago`;
      }

      const diffWeeks = Math.floor(diffDays / 7);
      if (diffDays <= 70) {
        return `${diffWeeks} weeks ago`;
      }

      const diffMonths = Math.floor(diffDays / 30);
      return `${diffMonths} months ago`;
    },
  },
});
